.image-gallery-icon {
  color: #7ed321;
  transition: all 0.2s ease-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
}

.image-gallery-icon:focus {
  outline: 2px solid #7ed321;
}

.image-gallery-icon:hover {
  color: #7ed321;
}
.image-gallery-icon:hover .image-gallery-svg {
  transform: scale(1.1);
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}
