body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.buttonDiv {
  background: #fff;
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-flow: column;
  padding-bottom: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.googleIconC {
  fill: #fbbb00;
}
.googleIconA {
  fill: #518ef8;
}
.googleIconB {
  fill: #28b446;
}
.googleIconD {
  fill: #f14336;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  padding: 15px;
}
